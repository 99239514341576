.carousel-inner>.item {
    -webkit-transition: -webkit-transform 0.3s ease-in-out !important;
    -o-transition: -o-transform 0.3s ease-in-out !important;
    transition: transform 0.3s ease-in-out !important;
}
.radio_check label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #565656;
    font-size: 14px;
    line-height:17px;
}

.carousel-indicators li {
    background-color: #756161;
}

/* Hide the browser's default radio button */
.radio_check label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio_check .checkmark {
    position: absolute;
    left: 0;
    top:0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid $main_color;
}

/* On mouse-over, add a grey background color */
.radio_check label:hover input ~ .checkmark {
    background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radio_check label input:checked ~ .checkmark {
    background-color: #fff;
    border: 2px solid $main_color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio_check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_check label input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio_check label .checkmark:after {
    top: 2px!important;
    left: 2px!important;
    width: 10px!important;
    height: 10px!important;
    border-radius: 50%;
    background: $main_color;
}