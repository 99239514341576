body {
  color: #555;
  font-family: $font-family-sans-serif;
  margin: 0;
  width: 100vw;
  padding-bottom: 60px;
  overflow-x: hidden;
  background-color: #f7f4f4;
}
.text-secondary {
  color: $secondary_color!important;
}
.mh-100 {max-height: 100px;}

input:focus {
  outline: none;
}

.border-color {
  box-shadow: inset 0px 0px 0px 2px $main_color;
  box-sizing: border-box;
}

textarea {
  resize: none;
}

.bg-0 {
  background-color: #dfdfdf !important;
}
.bg-1 {
  background-color: #f4ffed !important;
}

.btn {
  border-radius: 1.4rem !important;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.btn-danger {
  background-color: $main_color !important;
  border-color: $main_color;
}

.btn-group {
  display: flex;
}

.btn-group .btn {
  flex: 1;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-color {
  color: $main_color;
}

.btn-cancel {
  background-color: white;
  border: solid 1px #555;
  color: #555;
}

.btn-light.active {
  background-color: $main_color !important;
  color: white !important;
}

.bootstrap-switch,
.bootstrap-switch-handle-off,
.bootstrap-switch-handle-on {
  border-radius: 1rem !important;
}

.checkmark.right {
  left: auto;
  right: 0;
}

h1 {
  font-weight: 700;
  font-size: 18px;
  color: black;
  position: relative;
  padding: 15px 0;
  &.head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 2;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  .previousButton {
    position: absolute;
    left: 15px;
  }
}
.put-right {
  position: absolute;
  right: 15px;
}
.bg-color {
  background-color: $main_color;
  border-color: $main_color;
  color: white;
}
.col-form-label {
  font-weight: 700;
}

strong,
.strong {
  font-weight: 700;
}

.title {
  font-weight: 700;
}

.text-8 {font-size: 8px;}
.text-10 {font-size: 10px;}
.text-12 {font-size: 12px;}
.text-14 {font-size: 14px;}
.text-16 {font-size: 16px;}
.text-18 {font-size: 18px;}
.text-20 {font-size: 20px;}
.text-22 {font-size: 22px;}
.text-24 {font-size: 24px;}

.text-gray {
  color: #999;
  line-height: 12px;
}

.previousBtn {
  position: absolute;
  left: 30px;
  top: calc(50% - 20px);
}
.nextBtn {
  position: absolute;
  right: 30px;
  top: calc(50% - 20px);
}

.with-inter:before {
  content: "•";
  color: $main_color;
  position: absolute;
  left: calc(50% - 2px);
  bottom: -5px;
}

.full-height {
  height: calc(100vh - 58px);
  height: calc((var(--vh, 1vh) * 100) - 58px);
}

.data-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
}

iframe.content {
  width:100vw!important;
  max-width:100vw!important;
}

.dest-card {
  &.unread {
    border-color: $main_color;
    .preview {
      font-weight: 700;
    }
  }

  .message-time {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: #666;
  }
}

.navbar {
  z-index: 6;
}

.nav-pills .nav-link {
  font-weight: 700;
  padding: 0.4rem !important;
  background-color: white;
  &.active {
    background-color: $main_color;
  }
  border-radius: 1.4rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.card-photo {
  border-style: dashed;
  border-color: #ddd;
  border-width: 2px;
  background-color: white;
  color: $main_color;
  font-weight: 700;
}

.box-message {
  width: 100%;
  // float: left;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 60px;
  .message-body {
    color: #000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    word-break: break-all;
  }
  .card-body {
    border-radius: 0 10px 10px 10px;
    background-color: #555;
    color: white;
    margin-bottom: 15px;
    &.mine {
      border-radius: 10px 0px 10px 10px;
      background-color: #fff;
      color: #555;
    }
  }
  .message-inputer {
    position: fixed;
    width: 800px;
    bottom: 30px;
  }
}



.new-message {
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.font-400 {
  font-weight: 400;
}
a {
  color: #555;
  .link {
    color: $main_color;
    text-decoration: none;
  }
}

.floatingButton {
  position: fixed;
  z-index: 1000;
  bottom: 70px;
  right: 15px;
}

.pointer {
  cursor: pointer;
}

footer {
  position: fixed;
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  font-size: 15px;
  min-height: 60px;

  a {
    color: white;
    height: 55px;
    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }
  div.small {
    line-height: 16px;
  }
  em .badge {
    position: relative;
    border-radius: 50%;
    top: -12px;
    right: 0;
    font-family: $font-family-sans-serif;
    font-size: 9px;
  }
}

.selectDate.active {
  background-color: #555;
  color: white;
  small {
    color: white;
  }
}

.datepicker.datepicker-dropdown,
.table-condensed {
  width: 300px;
  height: 300px;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
  background-image: linear-gradient(to bottom, $main_color, #a31d21);
}

.ui-timepicker-standard {
  border-color: $main_color;
  border-radius: 20px;
  border-width: medium;
  z-index: 10000 !important;
}

@media (max-width: 600px) {
  .datepicker.datepicker-dropdown {
    width: 85vw;
    height: 80vw;
    .table-condensed {
      width: 80vw;
      height: 80vw;
    }
    td {
      font-size: 16px;
      border-radius: 10px;
    }
  }
}

// Desktop
// #sidebar { & ~ main { & XXXXX permet de cibler XXXXX qu'en mode desktop
//General

.pointer {
  cursor: pointer;
}
// Navbar

    nav.navbar.desktop {
      position: fixed;
      top:0;
      left:0;
      width:100%
    }


//Sidebar
#sidebar {
  text-transform: uppercase;
  font-weight: bold;
  width: 100px;
  background: $main_color;
  height: 100vh;
  position: fixed;
  z-index: 5;
  top: 0px;
  padding-top: 76px;
  & div > * {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #ffffff;
    transition: all 0.3s ease;
    &.active {
      background-color: $secondary_color;
    }
    &:hover {
      background-color: lighten($main_color, 10%);
    }
  }
  em {
    font-size: 1.5rem;
  }
  span {font-size: 0.8rem; font-weight: 600;}
  & ~ main {
    margin-left: 100px;
    margin-top: 20px;
  }
}

// cards

.clients {
  & .card-body {
    height: 58px;
    overflow-y: scroll;
  }
}

//size icon

.m-icon {
  font-size: 1.3em;
}

// Compte rendu

.historique .card-inter:not(:first-child) {
  border-top: solid 1px #D3D3D3;
}

.conversation {
  background: #ffffff;
  & .text-center.head,
  & .new-message {
    position: relative !important;
  }

  & h1 {
    padding: 15px;
  }
}

// Messagerie 

#sidebar {
  & ~ main {
    & .box-message {
      height: calc(100vh - 300px);
    }
  }
}

// Clients

#sidebar {
  & ~ main {
    & .demande-agence .mCustomScrollbar {
      height: 300px;
    }
    & .commandes .mCustomScrollbar {
      height: calc(100vh - 650px);
    }
    & .intersComm.mCustomScrollbar {
      height: calc(100vh - 315px);
    }

    & .informations.mCustomScrollbar {
      height: 450px;
    }

    & .documents.mCustomScrollbar {
      height: calc(100vh - 850px);
    }
  
    
    //Enlève le nom 
    & .card {
      // box-shadow: none !important;
      // border: none !important;
      // border-radius: 0 !important;
      // padding-top: 1rem !important;
      &:not(:first-child) {
        border-top: solid 1px #D3D3D3 !important;
      }
    }

    & .client-list {
      margin-top: -20px;
      transition: all 0.3s ease;
      &.opened {
        width: 400px;
      }

      &.closed {
        width: 40px;
        height: 100vh;
      }
      & .card {
        box-shadow: none !important;
        border: none !important;
        border-radius: 0 !important;
        &:not(:first-child) {
          border-top: solid 1px#D3D3D3 !important;
        }
      }

      & .toggle-arrow {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      & .floatingButton {
        bottom: 30px;
        position: absolute;
        right: 30px;
        left: unset;
      }
      & .hideseek-list {
        overflow-y: scroll;
        height: calc(100vh - 186px);
      }
    }
  }
}
